import { useLanguage } from "../../context/useLanguage";
import {
  getShowingLanguage,
  formatNumberToCurrency,
} from "../../utils/CommonUtils";
import { cartCardDataTransformer, getAmounts } from "./CartDataUtils";
import styles from "./CartCard.module.scss";
import TypeCard from "./TypeCard";
import { useState } from "react";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
export default function CartCard({
  performanceTicketData,
  // garanteeChecked,
  // onChangeGarantee,
}) {
  console.log("performanceTicketData are => ", performanceTicketData);
  const [couponCode, setCouponCode] = useState("");
  const { language } = useLanguage();
  const showingLanguage = getShowingLanguage(
    performanceTicketData[0].language,
    language
  );

  const transformedData = cartCardDataTransformer(
    performanceTicketData,
    showingLanguage
  );
  const { performanceName, eventName, date, address } = transformedData;
  const { subTotal, feesAmount, ticketsAmount } = getAmounts(
    performanceTicketData
  );

  const handleApplyCoupon = (e) => {
    e.preventDefault();
    console.log("handleApplyCoupon called");
  };

  return (
    <section className={styles.cartCard}>
      <div className={styles.cartCardContainer}>
        <div className={styles.cartCardHeader}>
          <h2 className={styles.eventName}>{eventName[showingLanguage]}</h2>
          <h4 className={styles.performanceName}>
            {performanceName[showingLanguage]}
          </h4>
          <p>{date}</p>
          <p>{address}</p>
        </div>
        <div className={styles.typeCardsContainer}>
          {performanceTicketData.map((type) => (
            <TypeCard
              typeData={type}
              showingLanguage={showingLanguage}
              key={type.typeId}
            />
          ))}
        </div>
        <div className={styles.cartCardFooter}>
          <div className={styles.cartCardFooterContainer}>
            <div className={styles.couponContainer}>
              <p>Have a coupon code?</p>
              <form className={styles.couponInput}>
                <input
                  type="text"
                  placeholder="Coupon Code"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                <button onClick={handleApplyCoupon}>Apply</button>
              </form>
            </div>
            <div className={styles.totalContainer}>
              <div className={styles.ticketsAmountContainer}>
                <p>Tickets:</p>
                <span className={styles.ticketsAmount}>
                  {formatNumberToCurrency(ticketsAmount)}
                </span>
              </div>
              {feesAmount > 0 && (
                <div className={styles.feesContainer}>
                  <p>Fees:</p>
                  <span className={styles.feesAmount}>
                    {formatNumberToCurrency(feesAmount)}
                  </span>
                </div>
              )}
              <div className={styles.subTotalContainer}>
                <p>Subtotal:</p>
                <span className={styles.subTotalAmount}>
                  {formatNumberToCurrency(subTotal)}
                </span>
              </div>
            </div>
          </div>
          {/* <div className={styles.garanteeContainer}>
            <CustomCheckbox
              onChangeChecked={onChangeGarantee}
              checked={garanteeChecked}
              variant="filterCheckbox"
            />
            <span className={styles.garanteeText}>
              Add a garantee to your order.
            </span>
            <button className={styles.garanteeInfoButton}>
              <FontAwesomeIcon icon={faCircleInfo} />
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
}
