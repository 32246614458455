import styles from "./TitleAndMessage.module.scss";

export default function TitleAndMessage({
  title,
  message,
  onTitleChange,
  onMessageChange,
  maxWords = 500,
  canEditTitle,
}) {
  const countWords = (text) => {
    return text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
  };

  const handleMessageChange = (e) => {
    const newText = e.target.value;
    const wordCount = countWords(newText);

    if (wordCount <= maxWords) {
      onMessageChange(newText);
    }
  };

  const currentWordCount = countWords(message);

  return (
    <div className={styles.titleAndMessage}>
      <div className={styles.titleContainer}>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => onTitleChange(e.target.value)}
          placeholder="Title"
          disabled={!canEditTitle}
        />
      </div>

      <div className={styles.messageContainer}>
        <div className={styles.labelContainer}></div>
        <textarea
          id="message"
          value={message}
          onChange={handleMessageChange}
          placeholder="Write your message..."
          rows={8}
        />
        <span className={styles.wordCount}>
          {currentWordCount}/{maxWords} words
        </span>
      </div>
    </div>
  );
}
