import React from "react";
import styles from "./Spinner.module.scss";
import eventLinxLogo from "../../../assets/eventlinx_logo.png";

export function Spinner() {
  return (
    <div className={styles.spinner}>
      <img src={eventLinxLogo} alt="eventlinx logo" />
      <div className={styles.spinnerInner}></div>
    </div>
  );
}

export default Spinner;
