import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import styles from "./MyOrders.module.scss";
import OrdersList from "./OrdersList";
import { getOrderList, cancelOrder } from "../../../utils/APIUtils";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../../hooks/useDebounce";
export default function MyOrders() {
  const [showContactOrganizerModal, setShowContactOrganizerModal] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const debouncedOrderDate = useDebounce(orderDate, 500);
  // const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  // const [debouncedOrderDate, setDebouncedOrderDate] = useState(orderDate);

  const {
    isLoading: ordersIsLoading,
    isError: ordersIsError,
    data: ordersResponse,
    error: ordersError,
  } = useQuery({
    queryKey: ["orders", debouncedSearchQuery, debouncedOrderDate],
    queryFn: () =>
      getOrderList({
        searchQuery: debouncedSearchQuery,
        orderDate: debouncedOrderDate,
      }),
    enabled: !debouncedSearchQuery || debouncedSearchQuery.length > 2,
  });
  const navigate = useNavigate();

  // const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  // const [refundReason, setRefundReason] = useState("");
  // const [selectedTab, setSelectedTab] = useState("upcoming");
  // const [orderRefundId, setOrderRefundId] = useState("");
  // const [feeDetails, setFeeDetails] = useState(null);

  const queryClient = useQueryClient();

  const {
    mutate: cancelOrderMutate,
    isPending: cancelOrderIsPending,
    error: cancelOrderError,
    isError: cancelOrderIsError,
  } = useMutation({
    mutationFn: cancelOrder,
    onSuccess: () => {
      // Invalidate and refetch orders after cancellation
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
    onError: (error) => {
      console.error("Failed to cancel order:", error);
      // Handle error - you might want to show a toast or error message
    },
  });

  // Check if the response has the 'data' property containing orders

  if (ordersIsLoading) return <div>Loading...</div>;

  if (ordersError) {
    console.log("ordersError", ordersError);
    return <div>Error fetching orders</div>;
  }

  if (ordersIsError) {
    console.log("ordersIsError", ordersIsError);
    console.log("ordersError", ordersError);
    const errorMessage = ordersError?.response?.data?.message;
    if (errorMessage === "Unauthorized") {
      navigate("/logout");
      return;
    } else {
      return <div>Error fetching orders</div>;
    }
  }

  const orders = ordersResponse?.data;
  console.log("orders: ", orders);
  // JSX to show when the search query is less than 3 characters long and thus
  const CompleteSearchCharacterCount = () => {
    return (
      <div>
        <h2>Please, type at least 3 characters to search.</h2>
      </div>
    );
  };

  return (
    <div className={styles.myOrders}>
      <div className={styles.myOrdersContainer}>
        <h2>Orders</h2>
        <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <input
            type="date"
            value={orderDate}
            onChange={(e) => setOrderDate(e.target.value)}
          />
        </div>
        {/* <div
          className={
            selectedTab === "upcoming"
              ? styles.eventTypes
              : `${styles.eventTypes} ${styles.pastSelected}`
          }
        >
          <button
            className={selectedTab === "upcoming" && styles.active}
            onClick={() => setSelectedTab("upcoming")}
          >
            <span>Upcoming Events</span>
          </button>
          <button
            className={selectedTab === "past" && styles.active}
            onClick={() => setSelectedTab("past")}
          >
            <span>Past Events</span>
          </button>
        </div> */}

        <div className={styles.eventsListContainer}>
          {orders && (
            <div className={styles.eventsList}>
              {!debouncedSearchQuery || debouncedSearchQuery.length > 2 ? (
                <OrdersList
                  ordersList={orders}
                  onCancelOrder={cancelOrderMutate}
                />
              ) : (
                <CompleteSearchCharacterCount />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
