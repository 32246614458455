import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import styles from "./CloseButton.module.scss";

export default function CloseButton({ variant, onClick }) {
  const btnClass = variant
    ? `${styles.closeBtn} ${styles[variant]}`
    : styles.closeBtn;
  return (
    <button className={btnClass} onClick={onClick}>
      <FontAwesomeIcon icon={faX} />
    </button>
  );
}
