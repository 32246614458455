import ResponseMessage from "../../../components/ModalContents/ResponseMessage/ResponseMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faTriangleExclamation,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

export const transformOrderData = (order) => {
  console.log("order on transformOrderData => ", order);

  return {
    orderId: order.transactionId,
    orderDate: new Date(order.purchaseDate).toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }),
    // isWithinFiveMinutes: orderDateObject >= fiveMinutesBeforeMidnight,
    eventId: order.eventId,
    eventName: order.eventName,
    orderAmount: order.amountTotal,
    tickets: order.ticketsDetails || [],
    paymentIntentId: order.paymentIntentId,
    promoterStripeId: order.promoterStripeId,
    status: order.status,
    isCanceled: order.status === "canceled",
    showCancelOrder: order.status === "requires_capture",
    startTime: order.startTime,
    promoterId: order.promoterId,
  };
};

export const transformTicketData = (ticket) => {
  console.log("ticket on transformTicketData => ", ticket);
  return {
    ticketId: ticket.ticketId,
    isRefundable: ticket.refundPolicy.isRefundable,
    refundableUntil: ticket.refundablePolice,
    performanceName: ticket.performanceName,
    performanceId: ticket.performanceId,
    sectionName: ticket.sectionName,
    typeName: ticket.typeName,
  };
};

export const transformCancelledTicketData = (ticket) => {
  console.log("ticket on transformCancelledTicketData => ", ticket);
  return {
    performanceName: ticket.performanceName,
    performanceId: ticket.performanceId,
    sectionName: ticket.sectionName,
    typeName: ticket.typeName,
    ticketCount: ticket.ticketCount,
  };
};

export const getStatusModalContent = (status, onClose) => {
  if (status === "success") {
    return (
      <ResponseMessage
        icon={<FontAwesomeIcon icon={faThumbsUp} />}
        title="SUCCESS!"
        msg="Your message was successfully sent."
        onClose={onClose}
        isSuccess={true}
      />
    );
  }
  if (status === "failure") {
    return (
      <ResponseMessage
        icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
        title="ERROR!"
        msg={
          <>
            <span>Error sending the message.</span>
            <span>Please try, again later.</span>
          </>
        }
        onClose={onClose}
      />
    );
  }
};
