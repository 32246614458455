import styles from "./Cart.module.scss";
import { useCart } from "../../context/useCart";
import CartData from "./CartData";
import Timer from "../../components/Timer/Timer";

export default function Cart() {
  const { cartCount } = useCart();

  // TODO: CREATE THE TIMER AND USE IT HERE IN THIS COMPONENTN ON THE cartSummaryContainer
  return (
    <div className={styles.cart}>
      <div className={styles.cartContainer}>
        <div className={styles.cartSummaryContainer}>
          <h3>Cart Summary</h3>
          <Timer />
        </div>
        {cartCount === 0 ? <h1>Cart is empty</h1> : <CartData />}
      </div>
    </div>
  );
}
