import styles from "./ContactOrganizer.module.scss";
import { useState } from "react";
import CustomCheckbox from "../../CustomCheckbox/CustomCheckbox";
import CustomButton from "../../CustomButton/CustomButton";
import TitleAndMessage from "../../TitleAndMessage/TitleAndMessage";
import { useModal } from "../../../context/useModal";
import CloseButton from "../../CloseButton/CloseButton";

// Component to contact the Organizer (Promoter)
export default function ContactOrganizer({
  orderId,
  tickets,
  showingLanguage,
  promoterId,
  onSendMessage,
  messageObj,
  onCancel,
}) {
  console.log("Tickets in ContactOrganizer => ", tickets);
  console.log("messageObj on ContactOrganizer => ", messageObj);
  console.log(
    "messageObj.messagePurpose on ContactOrganizer => ",
    messageObj?.messagePurpose
  );

  const MESSAGE_PURPOSES = {
    GENERAL_INQUIRY: "General Inquiry",
    REFUND_REQUEST: "Refund Request",
  };
  // const initialMessagePurpose = messageObj?.messagePurpose
  //   ? messageObj.messagePurpose
  //   : MESSAGE_PURPOSES.GENERAL_INQUIRY;

  // console.log("initialMessagePurpose => ", initialMessagePurpose);

  const { closeModal } = useModal();
  const [messagePurpose, setMessagePurpose] = useState(
    // initialMessagePurpose
    messageObj?.messagePurpose || MESSAGE_PURPOSES.GENERAL_INQUIRY
  );
  const [selectedTickets, setSelectedTickets] = useState(
    messageObj?.selectedTickets || []
  );
  const [messageTitle, setMessageTitle] = useState(
    messageObj?.messageTitle || ""
  );
  const [message, setMessage] = useState(messageObj?.message || "");

  console.log("tickets in ContactOrganizerModalContent is => ", tickets);
  console.log("message purpose => ", messagePurpose);

  // Filter the tickets that are refundable
  // The refundable tickets are the ones that are refundable
  // They also need to respect the amount of days before the start time set by the promoter
  const refundableTickets = tickets.filter((ticket) => {
    const performanceDateTimestamp = new Date(ticket.performanceDate).getTime();
    const now = new Date();
    const refundableUntil = new Date(
      performanceDateTimestamp -
        ticket.refundPolicy.daysBefore * 24 * 60 * 60 * 1000
    );

    console.log("refundableUntil is => ", refundableUntil);
    console.log("now is => ", now);

    const isRefundable =
      ticket.refundPolicy.isRefundable && refundableUntil > now;
    console.log("isRefundable is => ", isRefundable);
    return isRefundable;
  });

  const handleTicketSelection = (ticketId) => {
    setSelectedTickets((prev) => {
      if (prev.includes(ticketId)) {
        return prev.filter((id) => id !== ticketId);
      }
      return [...prev, ticketId];
    });
  };

  const handleSendPress = () => {
    const messageObj = {
      messageTitle,
      message,
      messagePurpose,
      orderId,
      promoterId,
    };
    if (messagePurpose === MESSAGE_PURPOSES.REFUND_REQUEST) {
      messageObj.selectedTickets = selectedTickets;
    }
    onSendMessage(messageObj);
  };

  const handlePurposeChange = (newValue) => {
    console.log("newValue in handlePurposeChange => ,", newValue);

    if (messagePurpose === MESSAGE_PURPOSES.REFUND_REQUEST) {
      setMessageTitle("");
      setSelectedTickets([]);
    }

    // Update the message purpose state
    setMessagePurpose(newValue);

    // Handle initialization logic for REFUND_REQUEST
    if (newValue === MESSAGE_PURPOSES.REFUND_REQUEST) {
      console.log("New value is request refund");
      setMessageTitle(`${MESSAGE_PURPOSES.REFUND_REQUEST} - Order# ${orderId}`);
    }
  };

  // Render the contact organizer page if !isMessageSent !isMessageError !isSendingMessage
  return (
    <div className={styles.contactOrganizer}>
      <div className={styles.closeBtnContainer}>
        <CloseButton onClick={closeModal} />
      </div>
      <h1 className={styles.contactOrganizerTitle}>Contact Organizer</h1>
      <h2 className={styles.orderNumber}>Order# {orderId}</h2>
      <div className={styles.messagePurposeContainer}>
        <label htmlFor="messagePurpose">Message Purpose:</label>
        <select
          id="messagePurpose"
          value={messagePurpose}
          onChange={(e) => handlePurposeChange(e.target.value)}
          className={styles.messagePurposeSelect}
        >
          {Object.values(MESSAGE_PURPOSES).map((purpose, index) => (
            <option key={`${purpose}-${index}`} value={purpose}>
              {purpose}
            </option>
          ))}
        </select>
      </div>

      {messagePurpose === MESSAGE_PURPOSES.REFUND_REQUEST && (
        <div className={styles.ticketSelectionContainer}>
          <h2 className={styles.ticketSelectionTitle}>
            Select Tickets for Refund
          </h2>
          {refundableTickets.map((ticket) => (
            <div key={ticket.id} className={styles.ticketItem}>
              <CustomCheckbox
                checked={selectedTickets.includes(ticket.ticketId)}
                onChangeChecked={() => handleTicketSelection(ticket.ticketId)}
                variant={"filterCheckbox"}
              />
              <span className={styles.ticketInfo}>
                {ticket.performanceName[showingLanguage]}/
                {ticket.typeName[showingLanguage]}
              </span>
            </div>
          ))}
        </div>
      )}
      <TitleAndMessage
        title={messageTitle}
        message={message}
        onTitleChange={setMessageTitle}
        onMessageChange={setMessage}
        canEditTitle={messagePurpose !== MESSAGE_PURPOSES.REFUND_REQUEST}
      />
      <div className={styles.buttonsConstainer}>
        <CustomButton
          title={"Send Message"}
          variant={"orderCard"}
          clickHandler={handleSendPress}
        />
        <CustomButton
          title={"Cancel"}
          variant={"orderCardOutlined"}
          clickHandler={onCancel}
        />
      </div>
    </div>
  );
}
