import styles from "./OrderCard.module.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faTriangleExclamation,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { formatNumberToCurrency } from "../../../utils/CommonUtils";
import TicketInfo from "./TicketInfo";
import { useLanguage } from "../../../context/useLanguage";
import {
  getLanguageArray,
  getShowingLanguage,
} from "../../../utils/CommonUtils";
import {
  getStatusModalContent,
  transformOrderData,
} from "./ordersTransformation";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { requestReceipt } from "../../../utils/APIUtils";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../context/useModal";
import ContactOrganizer from "../../../components/ModalContents/ContactOrganizer/ContactOrganizer";
import Spinner from "../../../components/ModalContents/Spinner/Spinner";
// import ResponseMessage from "../../../components/ModalContents/ResponseMessage/ResponseMessage";

const FEEDBACK_MESSAGES = {
  CONTACT_ORGANIZER_SUCCESS: "Message sent successfully",
  CONTACT_ORGANIZER_ERROR: "Error sending message",
  REFUND_REQUEST_SUCCESS: "Refund request sent successfully",
  REFUND_REQUEST_ERROR: "Error sending refund request",
};

// Card that shows order details
export default function OrderCard({ order, onCancelOrder }) {
  const [showMore, setShowMore] = useState(false);
  const [receiptError, setReceiptError] = useState(null);
  const [cancelReason, setCancelReason] = useState("change-of-plans");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isMessageError, setIsMessageError] = useState(false);
  const [messageObj, setMessageObj] = useState({});

  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  // TODO: Add error message to the UI, when we can not get the receipt

  console.log("order in OrderCard => ", order);
  // TODO: get the cancel reason enum from the backend

  const toggleShowMore = () => {
    setShowMore((prevValue) => !prevValue);
  };
  const transformedOrder = transformOrderData(order);
  const {
    orderId,
    orderDate,
    eventId,
    eventName,
    orderAmount,
    tickets,
    paymentIntentId,
    promoterStripeId,
    showCancelOrder,
    status,
    isCanceled,
    promoterId,
    startTime,
  } = transformedOrder;

  // Define showing languange
  // Get selected language on the website
  const { language } = useLanguage();
  // Get array of languages from performance name
  const languageArray = getLanguageArray(eventName);
  // Get showing language
  const showingLanguage = getShowingLanguage(languageArray, language);

  const handleSendMessage = (messageObject) => {
    console.log("messageObject is => ", messageObject);

    setMessageObj(messageObject);
    // TEMPORARY BEHAVIOR TO TEST
    // TODO: Hook it up with the API call and set the right state

    // setIsSendingMessage(true);
    // setIsMessageSent(true);
    setIsMessageError(true);
    /* 
      TODO: 
      MAKE AN API CALL AND SET IS SENDING TO TRUE
      AFTER RECEIVING THE API RESPONSE, UPDATE THE STATES ACCORDINGLY
    */
    // const messagePurpose = messageObj.messagePurpose;
    // switch (messagePurpose) {
    //   case "Refund Request":
    //     console.log("messagePurpose = General Inquiry - implement API Call");

    //     break;
    //   case "General Inquiry":
    //     console.log("messagePurpose = General Inquiry - implement API Call");
    //     break;

    //   default:
    //     break;
    // }
  };

  const handleCancelMsg = () => {
    setMessageObj({});
    closeModal();
  };

  // Handles the Contact organizer button press
  const handleContactOrganizer = () => {
    openModal(
      <ContactOrganizer
        orderId={orderId}
        tickets={tickets}
        showingLanguage={showingLanguage}
        promoterId={promoterId}
        onSendMessage={handleSendMessage}
        messageObj={messageObj}
        onCancel={handleCancelMsg}
      />,
      "whiteFullWidth"
    );
  };

  // Handles the receipt button press
  const handleReceipt = async () => {
    try {
      const receiptResponse = await requestReceipt(
        paymentIntentId,
        promoterStripeId
      );
      if (!receiptResponse.receipt) {
        setReceiptError("Receipt not found in response");
        return;
      }
      const receiptURL = receiptResponse.receipt;
      window.open(receiptURL, "_blank");
    } catch (error) {
      console.log("Error trying to request receipt: ", error);
      setReceiptError("Error trying to request receipt");
    }
  };

  const handleCancelOrder = async () => {
    onCancelOrder({ paymentIntentId, promoterStripeId, cancelReason });
  };

  const handleCloseResponseMessage = () => {
    if (isMessageError) {
      // Open modal with the data in the messageObj
      setIsMessageError(false);
      closeModal();
    }
    if (isMessageSent) {
      setIsMessageSent(false);
      setMessageObj({});
      closeModal();
    }
  };

  const openResponseMessage = (status) => {
    openModal(
      getStatusModalContent(status, handleCloseResponseMessage),
      "whiteFullWidth"
    );
  };

  // Use effect to handle the loading state, when there is no response regarding the message delivery
  useEffect(() => {
    if (isSendingMessage) {
      openModal(<Spinner />, "transparent");

      // TEMPORARY BEHAVIOR -- SENDING MESSAGE TO MIMIC RECEIVING THE API CALL RESPONSE
      setTimeout(() => {
        setIsSendingMessage(false);
        // setIsMessageSent(false);
      }, 3000);
    }
    if (!isSendingMessage) {
      closeModal();
    }
  }, [isSendingMessage]);

  // Use effect to handle the failed send message
  useEffect(() => {
    if (isMessageError) {
      openResponseMessage("failure");
    }
  }, [isMessageError]);

  // Use effect to handle the successful send message
  useEffect(() => {
    if (isMessageSent) {
      openResponseMessage("success");
    }
  }, [isMessageSent]);

  const className = isCanceled
    ? `${styles.orderCard} ${styles.canceled}`
    : styles.orderCard;

  return (
    <li className={className}>
      <div className={styles.orderCardHeader}>
        <div className={styles.orderDate}>
          <span className={styles.description}>Order Placed</span>
          <span className={styles.data}>{orderDate}</span>
        </div>
        <div className={styles.orderTotal}>
          <span className={styles.description}>Order Total</span>
          <span className={styles.data}>
            {formatNumberToCurrency(orderAmount)}
          </span>
        </div>
        <div className={styles.orderNumber}>
          <span className={styles.description}>Order #</span>
          <span className={styles.data}>{orderId}</span>
        </div>
      </div>
      <div className={styles.event}>
        <div className={styles.eventContainer}>
          <span>Event:</span>
          <Link className={styles.eventLink} to={`/events/${eventId}`}>
            {eventName[showingLanguage]}
          </Link>
        </div>
        {isCanceled && <span className={styles.canceled}>Cancelled</span>}
      </div>
      <div className={styles.btnsContainer}>
        <CustomButton
          clickHandler={handleContactOrganizer}
          title="Contact Organizer"
          variant="orderCard"
        />
        <CustomButton
          clickHandler={handleReceipt}
          title="Receipt"
          variant="orderCard"
        />
        {showCancelOrder && (
          <div className={styles.cancelOrderContainer}>
            <CustomButton
              clickHandler={handleCancelOrder}
              title="Cancel Order"
              variant="orderCardCancel"
            />
          </div>
        )}
      </div>
      <div className={styles.showMoreContainer}>
        <button className={styles.showMore} onClick={toggleShowMore}>
          {showMore ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </button>
      </div>
      {showMore && (
        <ul className={styles.ticketsInfoContainer}>
          {tickets.map((ticket, index) => (
            <TicketInfo
              ticketInfo={ticket}
              showingLanguage={showingLanguage}
              isCanceled={isCanceled}
              key={`ticket-${index}`}
            />
          ))}
        </ul>
      )}
    </li>
  );
}
