import styles from "./TicketInfo.module.scss";

import {
  transformCancelledTicketData,
  transformTicketData,
} from "./ordersTransformation";

// Shows the ticket info for each ticket of the order
export default function TicketInfo({
  ticketInfo,
  showingLanguage,
  isCanceled,
}) {
  console.log("ticketInfo in TicketInfo =>", ticketInfo);

  const ticketInfoTransformed = isCanceled
    ? transformCancelledTicketData(ticketInfo)
    : transformTicketData(ticketInfo);

  const { performanceName, sectionName, typeName } = ticketInfoTransformed;

  const handleRequestRefund = () => {
    console.log("request refund");
  };

  return (
    <li className={styles.ticketInfo}>
      <div className={styles.ticketNumberContainer}>
        <div className={styles.ticketId}>
          <span className={styles.ticketInfoHeader}>
            {isCanceled ? "Number of tickets: " : "Ticket #"}
          </span>
          <span className={styles.ticketInfoData}>
            {isCanceled
              ? ticketInfoTransformed.ticketCount
              : ticketInfoTransformed.ticketId}
          </span>
        </div>
      </div>
      <div className={styles.ticketInfoContainer}>
        <div className={styles.ticketInfoCol}>
          <span className={styles.ticketInfoHeader}>Performance</span>
          <span className={styles.ticketInfoData}>
            {performanceName[showingLanguage]}
          </span>
        </div>
        <div className={styles.ticketInfoCol}>
          <span className={styles.ticketInfoHeader}>Section</span>
          <span className={styles.ticketInfoData}>
            {sectionName[showingLanguage]}
          </span>
        </div>
        <div className={styles.ticketInfoCol}>
          <span className={styles.ticketInfoHeader}>Type</span>
          <span className={styles.ticketInfoData}>
            {typeName[showingLanguage]}
          </span>
        </div>
        {/* <div className={styles.ticketInfoCol}>
          <span className={styles.ticketInfoHeader}>Tax</span>
          <span className={styles.ticketInfoData}>
            {formatNumberToCurrency(tax)}
          </span>
        </div> */}
      </div>
    </li>
  );
}
